<template>
    <span  v-if="userId !== null && paidTime > 0"><span>{{prefix}}<strong>{{paidTime}}</strong>{{suffix}}</span></span>
</template>

<script>    
    import usersApi from '@/api/common/users';
    export default {
        data() {
            return {
                paidTime : 0
            };
        },
        props: {
            userId: {
                type: String,
                default: null,
            },
            prefix: {
                type: String,
                default: "",
            },
            suffix: {
                type: String,
                default: "",
            },
        },
        //watch: {
        //    userId(newVal) {
        //        if (this.userId !== null) {
        //            this.getPaidTime();
        //        }
        //    },
        //},
        mounted() { this.getPaidTime(); },
        methods: {
            getPaidTime() {
                if(this.userId !== null){
                    usersApi
                    .getCountRealTransaction(this.userId)
                    .then((response) => {
                        if (response.data && response.data.result === 0) {
                            this.$set(this, "paidTime", response.data.data);
                        } else {
                            if (response.data.message !== null && response.data.message !== "") {
                                console.error(response.data.message || this.$lang.common.error);
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                }
            }
        }
    }
</script>